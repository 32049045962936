import environment from './BASE';
import logoFss from 'assets/images/FSS/becolorful-logo.png';
import iconFss from 'assets/images/FSS/becolorful-favicon.ico';
const env = environment();

export default {
  ...env,
  api: {
    ...env.api,
    // Overwrite CB API version here
  },
  build_env: 'FSS',
  logo: logoFss,
  title: 'スタジオマネージャー',
  favicon: iconFss,
  redirect_sm_location: 'https://salon.nailie.jp/',
  redirect_fss_location: 'https://salon.becolorful.jp/',
};
